import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function BoatAndRvLoansPage() {
  return (
    <Page pathname="boat-and-rv-loans">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              <ContentBox title={data.TextBodyListA.title}>
                <ul>
                  {data.TextBodyListA.list?.map((element, index) => {
                    return (
                      <li key={`TextBodyList-${index}`} className="tw-mb-5">
                        {element.item}
                      </li>
                    );
                  })}
                </ul>
              </ContentBox>
              <Button is="a" href={data.ApplyButton.href} target="_blank">
                {data.ApplyButton.title}
              </Button>

              <Divider className="tw-my-7 sm:tw-my-10" />

              <ContentBox title={data.TextBodyListB.title}>
                <ul className="tw-list-disc tw-ml-6">
                  {data.TextBodyListB.list?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
              </ContentBox>
              <Divider className="tw-my-7 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default BoatAndRvLoansPage;
